import { BrowserRouter } from "react-router-dom";

//routes
import AllRoutes from ".";
import "../assets/css/materialdesignicons.min.css";
import "../assets/scss/style.scss";

const Routes = () => {
  return (
    <BrowserRouter>
        <AllRoutes />
    </BrowserRouter>
  );
};

export default Routes;
