import React, {Suspense} from "react";
import { useRoutes} from "react-router-dom";

//components

//landings
const index = React.lazy(() => import('../pages/Index'));
const termesConditions = React.lazy(() => import('../TermsAndConditionsUrl/TermsAndConditions'));


//auth
// const Login = React.lazy(() => import('../pages/auth/Login'))
// const SignUp = React.lazy(() => import('../pages/auth/Signin'))

const loading = () => <div className=""></div>;

type LoadingComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({component: Component}: LoadingComponentProps) => {
    return (
        <Suspense fallback={loading()}>
            <Component/>
        </Suspense>
    )
};

const AllRoutes = () => {
    return useRoutes([
        {
            path: '/',
            element: <LoadComponent component={index}/>,
        },
        {
            path: '/terms-and-conditions',
            element: <LoadComponent component={termesConditions}/>,
        }
    ])
}

export default AllRoutes;
